import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// Views
const DashboardMagazine = Loadable(lazy(() => import('../views/dashboard/Magazines')));
const AppUserAccountProfile = Loadable(lazy(() => import('../views/application/users/account-profile/Profile')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/magazines',

                '/user/account-profile/profile',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/magazines" component={DashboardMagazine} />
                        <Route path="/user/account-profile/profile" component={AppUserAccountProfile} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
