// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBook, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconBook: IconBook,
    IconSettings: IconSettings,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'magazines',
            title: <FormattedMessage id="magazines" />,
            type: 'item',
            url: '/dashboard/magazines',
            icon: icons['IconBook'],
            breadcrumbs: false
        },
        {
            id: 'account-settings',
            title: <FormattedMessage id="account-settings" />,
            type: 'item',
            url: '/user/account-profile/profile',
            icon: icons['IconSettings'],
            breadcrumbs: false
        }
    ]
};
