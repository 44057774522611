import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';

// assets
import logo from './../../../assets/images/logo.png';
import logoDark from './../../../assets/images/logo.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <img src={customization.navType === 'dark' ? logoDark : logo} alt="BTL" width="50" />
        </ButtonBase>
    );
};

export default LogoSection;
