const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    basename: '/',
    defaultPath: '/dashboard/magazines',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light',
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: "AIzaSyBSCw63uSXgEO4ZpfvG5kQNO5XaMORo2Js",
        authDomain: "zine-by-breaking-the-lines.firebaseapp.com",
        projectId: "zine-by-breaking-the-lines",
        storageBucket: "zine-by-breaking-the-lines.appspot.com",
        messagingSenderId: "1061534668715",
        appId: "1:1061534668715:web:99bcb63f0cfb29c0bee4ee",
        measurementId: "G-HRH9QJTK3B"
    },
    auth0: {
        client_id: 'D9yvEx9Bsi7j6OBpZ0rsoNucNme8SYCf',
        domain: 'breakingthelines.eu.auth0.com'
    },
    stripe: {
        api_key: 'pk_live_51IpiudCFM19jXrQD35eHDXfkEhCQsJKX59UZg9uIrOxPumtm6CLi54uJpoI2mEvduveTIRP87rmeJejpIbgUynMm00wtd01OJh',
        magazines: [{
            id: 'prod_JZzHotoYz5s8QO'
        }]
    }
};

export default config;
